/* out styles */

.logo-img {
  max-height: 42px;
}

.nk-sidebar-head {
  padding: 14px 24px 0px 20px;
}

.nk-sidebar {
  width: 81px !important;
}

@media (min-width: 1200px) {
  .has-sidebar .nk-wrap {
    padding-left: 81px;
  }
}

@media (min-width: 768px) {
  .nk-header-onlymobile {
    display: none;
  }

  .nk-content {
    margin-top: 100px;
  }
}

@media (min-width: 576px) {
  .nk-header {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media (max-width: 1200px) {
  .nk-content {
    margin-top: 80px !important;
  }
}

.auth-logo {
  max-height: 100px !important;
}

.logo-link {
  padding: 7px 0px 0px 7px !important;
}

.sidebar-logo-img {
  max-height: 65px !important;
}

.mobile-sidebar-logo-img {
  max-height: 65px !important;
  left: 0.6em;
}

.mobile-logo-link {
  padding-left: 0 !important;
}

.nk-apps-brand .logo-img:not(:first-child) {
  left: 0.6rem;
}

.dark-mode .css-1vmnjpn-skeletonStyles-Skeleton {
  background-color: #646464;
  background-image: linear-gradient(90deg, #646464, #888787, #646464);
}

.nk-ecwg .amount {
  font-size: 1.7rem;
}

.react-datepicker-wrapper {
  display: block !important;
}

.dark-mode .react-datepicker{
  background-color: #1b2633;
  border-color: #2a3a4e;
  box-shadow: 0 3px 20px 1px rgba(0, 0, 0, 0.4);
}

.dark-mode .react-datepicker__header {
  background-color: #1b2633;
  border-color: #3b526f;
}

.dark-mode .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff;
}

.dark-mode .react-datepicker__month-select {
  color: #fff;
  border: 1px solid #2a3a4e;
  background-color: #1b2633
}

.dark-mode .react-datepicker__year-select {
  color: #fff;
  border: 1px solid #3b526f;
  background-color: #1b2633
}

.dark-mode .react-datepicker-time__input {
    color: #fff;
    border: 1px solid #2a3a4e;
    background-color: #1b2633;
}

.react-datepicker__navigation--previous {
  border-right-color: #32977b !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #32977b !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #32977b !important;
}

.dark-mode .react-datepicker-time__caption {
color: #fff;
}

.dark-mode .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #fff !important;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #000 !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #32977b !important;
}

.react-datepicker__day--selected {
  background-color: #32977b !important
}